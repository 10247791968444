/***** EY Velocity Font Families*********/
@font-face {
    font-family: 'EYInterState-Bold';
    src: url('./~/assets/fonts/EYInterstate-Bold/EYInterstate-Bold.eot'); /* IE9+ Compat Modes */
    src: url('./~/assets/fonts/EYInterstate-Bold/EYInterstate-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./~/assets/fonts/EYInterstate-Bold/EYInterstate-Bold.woff') format('woff'), /* Modern browsers */
    url('./~/assets/fonts/EYInterstate-Bold/EYInterstate-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./~/assets/fonts/EYInterstate-Bold/EYInterstate-Bold.svg#FontName') format('svg'); /* Legacy iOS */
    font-style: normal;
}

@font-face {
    font-family: 'EYInterState-BoldItalic';
    src: url('./~/assets/fonts/EYInterstate-BoldItalic/EYInterstate-BoldItalic.eot'); /* IE9+ Compat Modes */
    src: url('./~/assets/fonts/EYInterstate-BoldItalic/EYInterstate-BoldItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./~/assets/fonts/EYInterstate-BoldItalic/EYInterstate-BoldItalic.woff') format('woff'), /* Modern browsers */
    url('./~/assets/fonts/EYInterstate-BoldItalic/EYInterstate-BoldItalic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./~/assets/fonts/EYInterstate-BoldItalic/EYInterstate-BoldItalic.svg#FontName') format('svg'); /* Legacy iOS  */
    font-style: italic;
}

@font-face {
    font-family: 'EYInterState-Italic';
    src: url('./~/assets/fonts/EYInterstate-Italic/EYInterstate-Italic.eot'); /* IE9+ Compat Modes */
    src: url('./~/assets/fonts/EYInterstate-Italic/EYInterstate-Italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./~/assets/fonts/EYInterstate-Italic/EYInterstate-Italic.woff') format('woff'), /* Modern browsers */
    url('./~/assets/fonts/EYInterstate-Italic/EYInterstate-Italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./~/assets/fonts/EYInterstate-Italic/EYInterstate-Italic.svg#FontName') format('svg'); /* Legacy iOS */
    font-style: italic;
}

@font-face {
    font-family: 'EYInterState-Light';
    src: url('./~/assets/fonts/EYInterstate-Light/EYInterstate-Light.eot'); /* IE9+ Compat Modes */
    src: url('./~/assets/fonts/EYInterstate-Light/EYInterstate-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('././~/assets/fonts/EYInterstate-Light/EYInterstate-Light.woff') format('woff'), /* Modern browsers */
    url('./~/assets/fonts/EYInterstate-Light/EYInterstate-Light.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./~/assets/fonts/EYInterstate-Light/EYInterstate-Light.svg#FontName') format('svg'); /* Legacy iOS */
    font-style: normal;
}

@font-face {
    font-family: 'EYInterState-LightItalic';
    src: url('./~/assets/fonts/EYInterstate-LightItalic/EYInterstate-LightItalic.eot'); /* IE9+ Compat Modes */
    src: url('./~/assets/fonts/EYInterstate-LightItalic/EYInterstate-LightItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./~/assets/fonts/EYInterstate-LightItalic/EYInterstate-LightItalic.woff') format('woff'), /* Modern browsers */
    url('./~/assets/fonts/EYInterstate-LightItalic/EYInterstate-LightItalic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./~/assets/fonts/EYInterstate-LightItalic/EYInterstate-LightItalic.svg#FontName') format('svg'); /* Legacy iOS */
    font-style: italic;
}

@font-face {
    font-family: 'EYInterState-Regular';
    src: url('./~/assets/fonts/EYInterstate-Regular/EYInterstate-Regular.eot'); /* IE9+ Compat Modes */
    src: url('./~/assets/fonts/EYInterstate-Regular/EYInterstate-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./~/assets/fonts/EYInterstate-Regular/EYInterstate-Regular.woff') format('woff'), /* Modern browsers */
    url('./~/assets/fonts/EYInterstate-Regular/EYInterstate-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./~/assets/fonts/EYInterstate-Regular/EYInterstate-Regular.svg#FontName') format('svg'); /* Legacy iOS */
    font-style: normal;
}

$EYInterState-Bold: 'EYInterState-Bold',sans-serif;
$EYInterState-BoldItalic: 'EYInterState-BoldItalic',sans-serif;
$EYInterState-Italic: 'EYInterState-Italic',sans-serif;
$EYInterState-Light: 'EYInterState-Light',sans-serif;
$EYInterState-LightItalic: 'EYInterState-LightItalic',sans-serif;
$EYInterState-Regular: 'EYInterState-Regular',sans-serif;

/***** DEFAULT EY THEME COLORS *********/
$EY_BLACK: #000000;
$EY_WHITE: #FFFFFF;

$EY_DARK_GRAY: #2E2E38;
$EY_GRAY_ALTERNATE: #646464;
$EY_GRAY: #808080;
$EY_GRAY_TINT_1: #999999;
$EY_GRAY_TINT_2: #C0C0C0;
$EY_GRAY_TINT_3: #F0F0F0;
$EY_GRAY_TINT_5: #1A1A24;
$EY_GRAY_TINT_6: #C1C4CE;
$EY_GRAY_TINT_7: #585860;


$EY_VELOCITY_PAGE_BG_1: #F6F6FA;
$EY_GRAY_TINT_4: #747480;
$EY_VELOCITY_Box_BG: #EAEAF2;

$EY_YELLOW: #FFE600;
$EY_PRIMARY: $EY_YELLOW;
$EY_SECODARY: #666666;

$EY_LINK_BLUE: #155CB4;
$EY_SPECIAL_RED: #F04C3E;

$EY_BLUE: #00A3AE;
$EY_PURPLE: #91278f;
$EY_GREEN: #2C973E;
$EY_LILAC: #AC98DB;

/***** DEFAULT EY VELOCITY THEME COLORS *********/
$EY_VELOCITY_ALT_BODY_BG: #838383;
$EY_VELOCITY_BODY_BG: #FFFFFF;
$EY_VELOCITY_PAGE_BG: #FFFFFF;
$EY_DRIVER_NAV_BG: #636363;
$EY_DRIVER_SUBNAV: #3C3C3C;
$EY_DRIVER_SUBNAV_LEVEL1: #3C3C3C;
$EY_DRIVER_SUBNAV_LEVEL2: #464646;
$EY_DRIVER_SUBNAV_LEVEL3: #4F4F4F;
$EY_DRIVER_SUBNAV_LEVEL4: #4F4F4F;



$font-size-x-lg: 38px;
$font-size-lg: 28px;
$font-size-md: 18px;
$font-size-reg: 14px;

$default_Font_Family: $EYInterState-Regular;
$default_Font_size: $font-size-reg;

$blue-card-color: #35A4E8;
$dark-blue-card-color: #336699;
$green-card-color: #34C768;
$black-card-color: $EY_BLACK;
$yellow-card-color: $EY_YELLOW;
$grey-card-color: $EY_DARK_GRAY;
$light-blue-card-color: #4D88C4 ;

$Rag_red: #FC272A;
$Rag_amber: #FD8526;
$Rag_green: #2AAD45;

$Scale_yellow: #FEE40A;
$Scale_orange: #61606D;
$Scale_blue: #B7B7C2;

$EY_FOOTER_BG: #2e2e38;
$SKY_BLUE:#8AD9F7;

$HIHU: #FFE600;
$HILU: #747480;
$LILU: #2E2E38;
$LIHU: #C4C4CD;